import { Controller } from '@hotwired/stimulus';
import InfiniteMarquee from 'vanilla-infinite-marquee';

import '../../node_modules/vanilla-infinite-marquee/infinite-marquee.scss';

export default class extends Controller {
    connect () {
        this.$marquee = new InfiniteMarquee({
            element: this.element,
            speed: 50000,
            direction: 'left',
            gap: '15px'
        });
    }

    disconnect () {
        this.$marquee?.destroy();
    }
}
